import React, { useEffect, useRef, useState } from "react";
import { format } from "date-fns";
import bcrypt from "bcryptjs";
import moment from "moment";

export const customGlobal = {
  PAGE_UNAUTHORIZED_ACCESS: "/Page401",
  PAGE_NOT_FOUND: "/Page404",
  SERVER_ERROR: "/Page500",
  SESSION_FAILED: "/SessionInfo",
};

export const defaultPageRights = {
  isCreate: false,
  isDelete: false,
  isEdit: false,
  isView: false,
};

export const configRole = {
  ADMIN: 1,
  HR: 9,
  PM: 4,
  QAM: 6,
};

export const maxUploadSize = 2048;
export const maxUploadSizeDisplay = "2 MB";

export const orderStatusMaster = {
  Saved: 1,
  Pending: 2,
  Partial_Approved: 3,
  Approved: 4,
  Hold: 5,
  Partial_Rejected: 6,
  Rejected: 7,
  Completed: 8,
  Cancelled: 9,
};

// export const googleMapApiKey = "AIzaSyCMU9wgPJFTfZLGFLRCj15djiiSvDAZLEU";

export const googleMapApiKey = "AIzaSyDda1pR3B2nVS2mM8qLark7cSZdxtSc_eE";

export const orderStatusReverseStringDisplayName = {
  saved: "1",
  pending: "2",
  completed: "8",
};

export const orderStatusDisplayName = {
  1: "Saved",
  2: "Pending",
  9: "Cancelled",
  8: "Completed",
};

export const cusTypeOfLeadList = {
  1: "Hot Lead",
  2: "Cold Lead",
  3: "Reference from Existing Client",
  4: "Enquiry from Salon",
  5: "Social Media",
};

export const cusSalonStatusList = {
  1: "Active",
  2: "Inactive",
  3: "Under Renovation",
  4: "Under transfer to new management",
};

export const cusAccountTypeList = {
  1: "Fragmented - upto 3 Location",
  2: "Local Chain - upto 3 city",
  3: "National Chain Level 2 - upto 3 State",
  4: "National Chain Level 1 -  5 State or more",
};

export const cusOutletTypeList = {
  1: "FOFO (Franchise Owned Franchise Operated)",
  2: "COFO (Company Owned Franchise Operated)",
  3: "COCO (Company Owned Company Operated)",
};
export const cusContactCategoryList = {
  Salon: 1,
  Owner: 2,
  Manager: 3,
  Staff: 4,
};
export const cusStaffCategoryList = {
  1: "Salon Contact",
  2: "Owner Contact",
  3: "Manager Contact",
};
export const cusStaffInfoWorkingSinceList = {
  1: "6 Months",
  2: "6 months to 1 year",
  3: "1-2 years",
  4: "3+ years",
};
export const cusManagementDesignationList = {
  1: "Junior Stylist",
  2: "Senior Stylist",
  3: "Creative Director",
  4: "Owner",
  5: "Partner",
};
export const cusStaffDesignationList = {
  101: "Receptionist",
  102: "Hair Stylist",
  103: "Floor Manager,",
  104: "Senior Stylist",
  105: "Creative Director",
  106: "Purchase Department",
  107: "Marketing Department",
  108: "Others",
};
export const cusCommonDesignationList = {
  1: "Junior Stylist",
  2: "Senior Stylist",
  3: "Creative Director",
  4: "Owner",
  5: "Partner",
  101: "Receptionist",
  102: "Hair Stylist",
  103: "Floor Manager,",
  104: "Senior Stylist",
  105: "Creative Director",
  106: "Purchase Department",
  107: "Marketing Department",
  108: "Others",
};
export const cusStaffStatusList = {
  1: "Active",
  2: "Discontinued",
};
export const cusPurchaseDecisionList = {
  1: "Whatsapp",
  2: "Email",
};
export const cusStaffInfoExpertiseList = {
  true: "Yes",
  false: "No",
};
export const cusCompanyType = {
  1: "SPC",
  2: "LLC",
  3: "Partnership",
  4: "Proprietorship",
  5: "Pvt Ltd",
  6: "Public Ltd",
};
export const cusGstList = {
  true: "Registered",
  false: "Unregistered",
};
export const cusFranchiseDetailList = {
  1: "Yes",
  2: "No",
};
export const orderApprovalMaster = {
  Dist_Approval: 10,
  ASM_Approval: 11,
  HO_Approval: 12,
  Dist_Reject: 13,
  ASM_Reject: 14,
  HO_Reject: 15,
  Partial_Paid: 16,
  Fully_Paid: 17,
  Refunded: 18,
  Failed: 19,
  Dist_Complete: 20,
  ASM_Complete: 21,
  HO_Complete: 22,
  ASM_Hold: 23,
  Open: 24,
  Closed: 25,
  In_Progress: 26,
  Reopen: 27,
  Review_Pending: 28,
  Waiting_for_Approve: 29,
};

export const saloonButtonCategories = [
  { value: "reception-Waiting-area", label: "Reception / Waiting Area" },
  { value: "interior", label: "Interior" },
  { value: "exterior", label: "Exterior" },
  { value: "display-area", label: "Display Area" },
  { value: "hair-wash-areas", label: "Hair Wash Areas" },
  { value: "branding-surfaces", label: "Branding Surfaces" },
];

export const orderApprovalConversion = {
  1: "Saved",
  2: "Pending",
  3: "Partial Approved",
  4: "Approved",
  5: "Hold",
  6: "Partial_Rejected",
  7: "Rejected",
  8: "Completed",
  9: "Cancelled",
  10: "Approved by Dist",
  11: "Approved by ASM",
  12: "Approved by HO",
  13: "Rejected by Dist",
  14: "Rejected by ASM",
  15: "Rejected by HO",
  16: "Partial_Paid",
  17: "Fully_Paid",
  18: "Refunded",
  19: "Failed",
  20: "Completed by Dist",
  21: "Completed by ASM",
  22: "Completed by HO",
  23: "Hold by ASM",
  24: "Open",
  25: "Closed",
  26: "In-progress",
  27: "Reopen",
  28: "Review Pending",
  29: "Waiting for Approve",
};

export const orderApprovalStatusDynamicFormat = {
  1: "Saved",
  2: "Pending",
  3: "Partial Approved",
  4: "Approved",
  5: "Hold",
  6: "Partial Rejected",
  7: "Rejected",
  8: "Completed",
  9: "Completed",
};

export const orderStatusStringDisplayName = {
  saved: "Saved Order",
  pending: "Pending Order",
  completed: "Completed Order",
};

export const orderStatusDisplayEnable = [
  orderStatusMaster.Saved,
  orderStatusMaster.Pending,
  orderStatusMaster.Cancelled,
  orderStatusMaster.Completed,
];

/** Enums for Batch Production Module */
// export const manufacturingMonth = {
//   1: "January",
//   2: "February",
//   3: "March",
//   4: "April",
//   5: "May",
//   6: "June",
//   7: "July",
//   8: "August",
//   9: "September",
//   10: "October",
//   11: "November",
//   12: "December"
// }

export const manufacturingMonth = [
  { value: 1, label: "January" },
  { value: 2, label: "February" },
  { value: 3, label: "March" },
  { value: 4, label: "April" },
  { value: 5, label: "May" },
  { value: 6, label: "June" },
  { value: 7, label: "July" },
  { value: 8, label: "August" },
  { value: 9, label: "September" },
  { value: 10, label: "October" },
  { value: 11, label: "November" },
  { value: 12, label: "December" },
];

export const manufacturingYear = [
  { value: 1, label: "2024" },
  { value: 2, label: "2025" },
];

export const productionType = {
  1: "Batch Production",
  2: "Individual Production",
};

export const getOrderApprovalStatusDynamicFormat = (statusId) => {
  var dynstatusId = null;
  switch (statusId) {
    case orderApprovalMaster.Dist_Approval:
    case orderApprovalMaster.ASM_Approval:
    case orderApprovalMaster.HO_Approval:
      dynstatusId = orderStatusMaster.Approved;
      break;
    case orderApprovalMaster.Dist_Reject:
    case orderApprovalMaster.ASM_Reject:
    case orderApprovalMaster.HO_Reject:
      dynstatusId = orderStatusMaster.Rejected;
      break;
    case orderApprovalMaster.Dist_Complete:
    case orderApprovalMaster.ASM_Complete:
    case orderApprovalMaster.HO_Complete:
      dynstatusId = orderStatusMaster.Completed;
      break;
    case orderApprovalMaster.ASM_Hold:
      dynstatusId = orderStatusMaster.Hold;
      break;
    default:
      break;
  }
  //return orderApprovalStatusDynamicFormat[dynstatusId];
  return orderApprovalConversion[statusId];
};

export const formatDate = (value) => {
  const formattedDate =
    value == null ? "" : format(new Date(value), "dd-MM-yyyy");
  return formattedDate;
};

export const formatTime = (value) => {
  const formattedTime =
    value == null ? "" : format(new Date(value), "HH:mm:ss");
  return formattedTime;
};

export const formatDateTime = (value) => {
  const formatDateTime =
    value == null ? "" : moment.utc(value).local().format("DD-MM-yyyy HH:mm");
  return formatDateTime;
};

// export const formatGenericPrice = (data) => {
//   if (data == null || data == undefined || data == "") {
//     return 0.0;
//   }
//   return data.toFixed(2);
// };

export const formatGenericPrice = (data) => {
  if (data == null || data == undefined || data === "") {
    return "0.00";
  }
  const numericData = parseFloat(data);
  return numericData.toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 2,
  });
};
export const toCamelCase = (value) => {
  return value
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
};

export const toSnakeCase = (value) => {
  const sentenceCaseText = value
    .replace(/_/g, " ")
    .replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );

  return sentenceCaseText;
};

export const encryptedBase64Password = (password) => {
  var base64EncryptedPassword = password;
  for (var i = 0; i < 5; i++) {
    /* base64EncryptedPassword = Buffer.from(base64EncryptedPassword).toString(
      "base64"
    );*/
    base64EncryptedPassword = btoa(base64EncryptedPassword);
  }
  return base64EncryptedPassword;
};

export const decryptBase64Password = (base64EncryptedPassword) => {
  var base64DecryptedPassword = base64EncryptedPassword;
  for (var i = 0; i < 5; i++) {
    /*
    base64DecryptedPassword = Buffer.from(
      base64DecryptedPassword,
      "base64"
    ).toString();*/
    base64DecryptedPassword = atob(base64DecryptedPassword);
  }
  return base64DecryptedPassword;
};

export const encryptedBcryptPassword = (password) => {
  var salt = bcrypt.genSaltSync(10);
  var hashPassword = bcrypt.hashSync(password, salt);
  return hashPassword;
};

export const activeInActiveStatus = {
  false: "No",
  true: "Yes",
};

export const getActiveInActiveStatus = (value) => {
  return activeInActiveStatus[value];
};

export const validateMobileNumber = (mobileNumber) => {
  const mobileRegex = /^[6-9]\d{9}$/;
  return mobileRegex.test(mobileNumber);
};

export const validateEmailAddress = (email) => {
  // const emailRegex =
  //  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const emailRegex = /^\S+@\S+\.\S+$/;
  return emailRegex.test(email);
};

export const rightsPageList = {
  Home: "Home",
  MyVisits: "MyVisits",
  MyVisitsNew: "MyVisitsNew",
  MyBilling: "MyBilling",
  MyTasks: "MyTasks",
  Marketing: "Marketing",
  SalonMap: "SalonMap",
  Calendar: "Calendar",
  MyTravel: "MyTravel",
  ProductList: "ProductList",
  TypeOfServicesList: "TypeOfServicesList",
  FranchiseDirectoryList: "FranchiseDirectoryList",
  BrandMasterList: "BrandMasterList",
  BrandMaster: "BrandMaster",
  FeedBackFormList: "FeedBackFormList",
  FeedBackForm: "FeedBackForm",
  // BatchList: "BatchList",
  Batch: "Batch",
  Product: "Product",
  TypeOfServices: "TypeOfServices",
  FranchiseDirectory: "FranchiseDirectory",
  UserList: "UserList",
  User: "User",
  Landing: "Landing",
  OrderByStatus: "OrderByStatus",
  OrderDetail: "OrderDetail",
  OrderDetailDist: "OrderDetailDist",
  OrderDetailGeneric: "OrderDetailGeneric",
  OrgChart: "OrgChart",
  ActivityLogs: "ActivityLogs",
  Inventory: "Inventory",
  InventoryList: "InventoryList",
  CustomerDetail: "CustomerDetail",
  CustomerDetailNew: "CustomerDetailNew",
  SearchMaster: "SearchMaster",
  CustomerDetailList: "CustomerDetailList",
  AddDistributor: "AddDistributor",
  DistributorList: "DistributorList",
  /*Beat Plan*/
  BeatPlanList: "BeatPlanList",
  BeatPlanSelected: "BeatPlanSelected",
  BeatPlanGenerateView: "BeatPlanGenerateView",
  NewBeatPlanGenerate:"NewBeatPlanGenerate",
  CreateBeatPlanView:"CreateBeatPlanView",
  /**Generate QR Code */
  GenerateQRCode: "GenerateQRCode",
  ProductQRList: "ProductQRList",
  MultipleQRProductView: "MultipleQRProductView",
};

export const rightsRoleList = {
  IT_ADMIN_PRODUCT_ADMIN_SALES_ADMIN: 1,
  CEO: 2,
  ZSM: 3,
  RSM: 4,
  ASM: 5,
  DISTRIBUTOR: 6,
  BDE: 7,
  PRODUCT_ADMIN: 8,
  SALES_ADMIN: 9,
  HOS: 10,
  TECHNICAL_TRAINER: 11,
  EQ_ADMIN: 12,
};

export const rightsRoleNameList = {
  1: "IT_ADMIN_PRODUCT_ADMIN_SALES_ADMIN",
  2: "CEO",
  3: "ZSM",
  4: "RSM",
  5: "ASM",
  6: "DISTRIBUTOR",
  7: "BDE",
  8: "PRODUCT_ADMIN",
  9: "SALES_ADMIN",
  10: "HOS",
  11: "TECHNICAL_TRAINER",
  12: "EQ_ADMIN",
};

export const getPageRights = (menuEnumScreen) => {
  var resultObj = {
    isCreate: false,
    isDelete: false,
    isEdit: false,
    isView: false,
  };
  var usrRoleId = localStorage.getItem("usrRoleId");

  switch (parseInt(usrRoleId)) {
    case rightsRoleList.IT_ADMIN_PRODUCT_ADMIN_SALES_ADMIN:
    case rightsRoleList.CEO:
    case rightsRoleList.ZSM:
    case rightsRoleList.RSM:
    case rightsRoleList.PRODUCT_ADMIN:
    case rightsRoleList.SALES_ADMIN:
    case rightsRoleList.HOS:
      switch (menuEnumScreen) {
        case rightsPageList.Home:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.OrderDetailGeneric:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.OrgChart:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.ActivityLogs:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;

        case rightsPageList.OrderDetailDist:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.ProductList:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.TypeOfServicesList:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.FranchiseDirectoryList:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.BrandMasterList:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.BrandMaster:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.FeedBackFormList:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.Batch:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.Product:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.TypeOfServices:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.FranchiseDirectory:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.FeedBackForm:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;

        case rightsPageList.UserList:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.User:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.OrderByStatus:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.OrderDetail:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.Calendar:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;

        case rightsPageList.Inventory:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.InventoryList:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;

        case rightsPageList.CustomerDetail:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.CustomerDetailNew:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
        case rightsPageList.SearchMaster:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };

          break;
        case rightsPageList.CustomerDetailList:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.ProductQRList:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.MultipleQRProductView:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;

        // case rightsPageList.AddDistributor:
        //   resultObj = {
        //     isCreate: false,
        //     isDelete: false,
        //     isEdit: false,
        //     isView: true,
        //   };
        //   break;
        //   case rightsPageList.RegitrationComponents:
        // resultObj = {
        //   isCreate: false,
        //   isDelete: false,
        //   isEdit: false,
        //   isView: true,
        // };
        // break;
        default:
          break;
      }

      break;
    case rightsRoleList.ASM:
      switch (menuEnumScreen) {
        case rightsPageList.Home:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.OrderDetailGeneric:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.OrderDetail:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.CustomerDetail:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.CustomerDetailList:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.FeedBackFormList:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.BeatPlanGenerateView:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
          case rightsPageList.NewBeatPlanGenerate:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
          case rightsPageList.PlannedBeats:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break; 
          case rightsPageList.AssignBeatPlanView:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
          case rightsPageList.CreateBeatPlanView:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
          
        case rightsPageList.BeatPlanList:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.BeatPlanSelected:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        default:
          break;
      }
      break;
    case rightsRoleList.DISTRIBUTOR:
      switch (menuEnumScreen) {
        case rightsPageList.Home:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.OrderDetailGeneric:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.OrderDetailDist:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;

        case rightsPageList.Inventory:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.InventoryList:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.AddDistributor:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.DistributorList:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.FeedBackFormList:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        default:
          break;
      }
      break;
    case rightsRoleList.EQ_ADMIN:
      switch (menuEnumScreen) {
        case rightsPageList.Home:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.FeedBackFormList:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        default:
          break;
      }
      break;
    case rightsRoleList.BDE:
      switch (menuEnumScreen) {
        case rightsPageList.Home:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.MyVisits:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
          case rightsPageList.MyVisitsNew:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.CustomerDetail:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.CustomerDetailList:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.OrderByStatus:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.MyBilling:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.MyTasks:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.Marketing:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.SalonMap:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.Calendar:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.MyTravel:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.FeedBackFormList:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
        case rightsPageList.BeatPlanGenerateView:
          resultObj = {
            isCreate: false,
            isDelete: false,
            isEdit: false,
            isView: true,
          };
          break;
      }
    case rightsPageList.BeatPlanList:
      resultObj = {
        isCreate: false,
        isDelete: false,
        isEdit: false,
        isView: true,
      };
      break;
    case rightsPageList.BeatPlanSelected:
      resultObj = {
        isCreate: false,
        isDelete: false,
        isEdit: false,
        isView: true,
      };
      break;
    default:
      break;
  }
  return resultObj;
};

export const tempBeatPlanDetails = {
  success: true,
  content: [
    {
      beatPlanId: 10,
      beatPlanName: "Bengaluru ASR Circle",
      beatPlanBDEId: 47,
      beatPlanManagerId: 19,
      beatPlanStartPointLat: "10.58754041646985",
      beatPlanStartPointLng: "77.12209577706753",
      beatPlanEndPointLat: "14.02718964207018",
      beatPlanEndPointLng: "77.0528575110019",
      beatPlanTotalDistance: 3422.0,
      beatPlanIsActive: true,
      bdeName: "Uthra BDE",
      cusDetails: [
        {
          cusId: 24,
          cusName: "Lees",
          customerCategory: "Bronze Salon",
          cusfrancise: null,
          cusAddess1: "Vijaynagar ",
          cusAddress2: "Vijaynagar ",
          cusPostalCode: "570017",
          cusLocality: "Vijaynagar ",
          cusZone: null,
          cusManagerName: null,
          cusManagerContact: null,
          cusMobileNumber: null,
          cusDescription: null,
          cusIsActive: true,
          cusLat: "20.15671198805519",
          cusLong: "77.82655434770211",
          customerSince: "2024-03-13T08:54:04.3917418",
          orderDetails: [],
          orderCountDetails: [],
          retailAndProfessionalCountDetails: [],
        },
        {
          cusId: 27,
          cusName: "Sanas",
          customerCategory: "Bronze Salon",
          cusfrancise: null,
          cusAddess1: "Vijaynagar ",
          cusAddress2: "Vijaynagar ",
          cusPostalCode: "570017",
          cusLocality: "Vijaynagar ",
          cusZone: null,
          cusManagerName: null,
          cusManagerContact: null,
          cusMobileNumber: null,
          cusDescription: null,
          cusIsActive: true,
          cusLat: "14.02718964207018",
          cusLong: "77.0528575110019",
          customerSince: "2024-03-13T10:33:20.6627264",
          orderDetails: [],
          orderCountDetails: [],
          retailAndProfessionalCountDetails: [],
        },
        {
          cusId: 28,
          cusName: "Blush and rush",
          customerCategory: "Bronze Salon",
          cusfrancise: null,
          cusAddess1: "Vijaynagar ",
          cusAddress2: "Vijaynagar ",
          cusPostalCode: "570017",
          cusLocality: "Vijaynagar ",
          cusZone: null,
          cusManagerName: null,
          cusManagerContact: null,
          cusMobileNumber: null,
          cusDescription: null,
          cusIsActive: true,
          cusLat: "12.85692593105044",
          cusLong: "77.47845736908437",
          customerSince: "2024-03-13T11:02:53.9407292",
          orderDetails: [],
          orderCountDetails: [],
          retailAndProfessionalCountDetails: [],
        },
        {
          cusId: 29,
          cusName: "ELEGANT",
          customerCategory: "Bronze Salon",
          cusfrancise: null,
          cusAddess1: "Vijaynagar ",
          cusAddress2: "Vijaynagar ",
          cusPostalCode: "570017",
          cusLocality: "Vijaynagar ",
          cusZone: null,
          cusManagerName: null,
          cusManagerContact: null,
          cusMobileNumber: null,
          cusDescription: null,
          cusIsActive: true,
          cusLat: "21.90000691943852",
          cusLong: "76.30390272527855",
          customerSince: "2024-03-13T12:05:07.4145716",
          orderDetails: [],
          orderCountDetails: [],
          retailAndProfessionalCountDetails: [],
        },
        {
          cusId: 40,
          cusName: "Magic hands",
          customerCategory: "Bronze Salon",
          cusfrancise: null,
          cusAddess1: "Gokulam",
          cusAddress2: "Gokulam",
          cusPostalCode: "570002",
          cusLocality: "Gokulam",
          cusZone: null,
          cusManagerName: null,
          cusManagerContact: null,
          cusMobileNumber: null,
          cusDescription: null,
          cusIsActive: true,
          cusLat: "21.47973091776069",
          cusLong: "77.90857168386862",
          customerSince: "2024-03-15T05:14:15.6349334",
          orderDetails: [],
          orderCountDetails: [],
          retailAndProfessionalCountDetails: [],
        },
      ],
    },
    {
      beatPlanId: 9,
      beatPlanName: "Mysuru KSR Circle",
      beatPlanBDEId: 47,
      beatPlanManagerId: 19,
      beatPlanStartPointLat: "19.92632584566365",
      beatPlanStartPointLng: "77.88066312743449",
      beatPlanEndPointLat: "18.28163113643164",
      beatPlanEndPointLng: "77.84680059440716",
      beatPlanTotalDistance: 3235.0,
      beatPlanIsActive: true,
      bdeName: "Uthra BDE",
      cusDetails: [
        {
          cusId: 14,
          cusName: "Sample Salon Mysuru",
          customerCategory: "Bronze Salon",
          cusfrancise: null,
          cusAddess1: "Address 1",
          cusAddress2: "Address 2",
          cusPostalCode: "570009",
          cusLocality: "Mysuru",
          cusZone: null,
          cusManagerName: null,
          cusManagerContact: null,
          cusMobileNumber: null,
          cusDescription: null,
          cusIsActive: true,
          cusLat: "18.89176370246864",
          cusLong: "77.43468874913016",
          customerSince: "2024-03-13T06:32:45.4493526",
          orderDetails: [],
          orderCountDetails: [],
          retailAndProfessionalCountDetails: [],
        },
        {
          cusId: 15,
          cusName: "Aurora ",
          customerCategory: "Bronze Salon",
          cusfrancise: null,
          cusAddess1: "Vontikoppal",
          cusAddress2: "Vontikoppal",
          cusPostalCode: "570002",
          cusLocality: "Vontikoppal",
          cusZone: null,
          cusManagerName: null,
          cusManagerContact: null,
          cusMobileNumber: null,
          cusDescription: null,
          cusIsActive: true,
          cusLat: "19.92632584566365",
          cusLong: "77.88066312743449",
          customerSince: "2024-03-13T06:35:21.9505259",
          orderDetails: [
            {
              ordHeaderId: 2,
              ordHeaderNumber: null,
              ordHeaderCustomerId: 15,
              ordHeaderVisitId: null,
              ordHeaderTotalAmount: 2415.0,
              ordHeaderDistributorId: 1,
              ordHeaderStatus: 4,
              ordHeaderIsActive: false,
              ordHeaderDate: "2024-03-13T06:46:34.0454817",
              retail: 1,
              professional: 1,
              totalProducts: 2,
              retailPercentage: 50,
              professionalPercentage: 50,
              pending1: 131,
              pending2: "5,600",
              status: "Approved",
            },
          ],
          orderCountDetails: [
            {
              pending: 1,
              completed: 0,
              saved: 0,
            },
          ],
          retailAndProfessionalCountDetails: [
            {
              retail: 1,
              professional: 1,
              totalProducts: 2,
              retailPercentage: 50,
              professionalPercentage: 50,
            },
          ],
        },
        {
          cusId: 17,
          cusName: "STUDIO 11 UNISEX SALON ",
          customerCategory: "Bronze Salon",
          cusfrancise: null,
          cusAddess1: "Vijaynagar ",
          cusAddress2: "Vijaynagar ",
          cusPostalCode: "570032",
          cusLocality: "Vijaynagar ",
          cusZone: null,
          cusManagerName: null,
          cusManagerContact: null,
          cusMobileNumber: null,
          cusDescription: null,
          cusIsActive: true,
          cusLat: "13.32598728107961",
          cusLong: "76.74123199857348",
          customerSince: "2024-03-13T07:38:51.5730602",
          orderDetails: [
            {
              ordHeaderId: 23,
              ordHeaderNumber: null,
              ordHeaderCustomerId: 17,
              ordHeaderVisitId: 153,
              ordHeaderTotalAmount: 8575.0,
              ordHeaderDistributorId: 1,
              ordHeaderStatus: 5,
              ordHeaderIsActive: false,
              ordHeaderDate: "2024-03-23T07:02:12.8098537",
              retail: 3,
              professional: 0,
              totalProducts: 3,
              retailPercentage: 100,
              professionalPercentage: 0,
              pending1: 121,
              pending2: "5,600",
              status: "Hold",
            },
            {
              ordHeaderId: 24,
              ordHeaderNumber: null,
              ordHeaderCustomerId: 17,
              ordHeaderVisitId: 153,
              ordHeaderTotalAmount: 0.0,
              ordHeaderDistributorId: 1,
              ordHeaderStatus: 1,
              ordHeaderIsActive: false,
              ordHeaderDate: "2024-03-23T11:24:53.5092755",
              retail: 0,
              professional: 0,
              totalProducts: 0,
              retailPercentage: 0,
              professionalPercentage: 0,
              pending1: 121,
              pending2: "5,600",
              status: "Saved",
            },
            {
              ordHeaderId: 25,
              ordHeaderNumber: null,
              ordHeaderCustomerId: 17,
              ordHeaderVisitId: 153,
              ordHeaderTotalAmount: 4200.0,
              ordHeaderDistributorId: 1,
              ordHeaderStatus: 1,
              ordHeaderIsActive: false,
              ordHeaderDate: "2024-03-23T11:25:38.6548219",
              retail: 1,
              professional: 0,
              totalProducts: 1,
              retailPercentage: 100,
              professionalPercentage: 0,
              pending1: 121,
              pending2: "5,600",
              status: "Saved",
            },
            {
              ordHeaderId: 26,
              ordHeaderNumber: null,
              ordHeaderCustomerId: 17,
              ordHeaderVisitId: 153,
              ordHeaderTotalAmount: 0.0,
              ordHeaderDistributorId: 1,
              ordHeaderStatus: 1,
              ordHeaderIsActive: false,
              ordHeaderDate: "2024-03-23T11:28:34.2866616",
              retail: 0,
              professional: 0,
              totalProducts: 0,
              retailPercentage: 0,
              professionalPercentage: 0,
              pending1: 121,
              pending2: "5,600",
              status: "Saved",
            },
            {
              ordHeaderId: 27,
              ordHeaderNumber: null,
              ordHeaderCustomerId: 17,
              ordHeaderVisitId: 153,
              ordHeaderTotalAmount: 0.0,
              ordHeaderDistributorId: 1,
              ordHeaderStatus: 1,
              ordHeaderIsActive: false,
              ordHeaderDate: "2024-03-23T11:32:10.8085135",
              retail: 0,
              professional: 0,
              totalProducts: 0,
              retailPercentage: 0,
              professionalPercentage: 0,
              pending1: 121,
              pending2: "5,600",
              status: "Saved",
            },
            {
              ordHeaderId: 28,
              ordHeaderNumber: null,
              ordHeaderCustomerId: 17,
              ordHeaderVisitId: 170,
              ordHeaderTotalAmount: 5250.0,
              ordHeaderDistributorId: 1,
              ordHeaderStatus: 1,
              ordHeaderIsActive: false,
              ordHeaderDate: "2024-03-25T07:58:46.3051548",
              retail: 1,
              professional: 0,
              totalProducts: 1,
              retailPercentage: 100,
              professionalPercentage: 0,
              pending1: 119,
              pending2: "5,600",
              status: "Saved",
            },
            {
              ordHeaderId: 29,
              ordHeaderNumber: null,
              ordHeaderCustomerId: 17,
              ordHeaderVisitId: 170,
              ordHeaderTotalAmount: 3825.0,
              ordHeaderDistributorId: 1,
              ordHeaderStatus: 2,
              ordHeaderIsActive: false,
              ordHeaderDate: "2024-03-25T07:59:11.4540653",
              retail: 0,
              professional: 1,
              totalProducts: 1,
              retailPercentage: 0,
              professionalPercentage: 100,
              pending1: 119,
              pending2: "5,600",
              status: "Pending",
            },
          ],
          orderCountDetails: [
            {
              pending: 2,
              completed: 0,
              saved: 5,
            },
          ],
          retailAndProfessionalCountDetails: [
            {
              retail: 5,
              professional: 1,
              totalProducts: 6,
              retailPercentage: 83.33333333333334,
              professionalPercentage: 16.666666666666664,
            },
          ],
        },
        {
          cusId: 18,
          cusName: "Lakme saloon",
          customerCategory: "Bronze Salon",
          cusfrancise: null,
          cusAddess1: "Vijaynagar ",
          cusAddress2: "Vijaynagar ",
          cusPostalCode: "570032",
          cusLocality: "Vijaynagar ",
          cusZone: null,
          cusManagerName: null,
          cusManagerContact: null,
          cusMobileNumber: null,
          cusDescription: null,
          cusIsActive: true,
          cusLat: "26.9390828790009",
          cusLong: "76.39496028603757",
          customerSince: "2024-03-13T08:11:20.8847887",
          orderDetails: [],
          orderCountDetails: [],
          retailAndProfessionalCountDetails: [],
        },
        {
          cusId: 20,
          cusName: "Miraval",
          customerCategory: "Bronze Salon",
          cusfrancise: null,
          cusAddess1: "Vijaynagar ",
          cusAddress2: "Vijaynagar ",
          cusPostalCode: "570032",
          cusLocality: "Vijaynagar ",
          cusZone: null,
          cusManagerName: null,
          cusManagerContact: null,
          cusMobileNumber: null,
          cusDescription: null,
          cusIsActive: true,
          cusLat: "18.28163113643164",
          cusLong: "77.84680059440716",
          customerSince: "2024-03-13T08:38:05.4903791",
          orderDetails: [
            {
              ordHeaderId: 4,
              ordHeaderNumber: null,
              ordHeaderCustomerId: 20,
              ordHeaderVisitId: null,
              ordHeaderTotalAmount: 665.0,
              ordHeaderDistributorId: 1,
              ordHeaderStatus: 1,
              ordHeaderIsActive: false,
              ordHeaderDate: "2024-03-13T08:39:32.3336972",
              retail: 1,
              professional: 0,
              totalProducts: 1,
              retailPercentage: 100,
              professionalPercentage: 0,
              pending1: 131,
              pending2: "5,600",
              status: "Saved",
            },
          ],
          orderCountDetails: [
            {
              pending: 0,
              completed: 0,
              saved: 1,
            },
          ],
          retailAndProfessionalCountDetails: [
            {
              retail: 1,
              professional: 0,
              totalProducts: 1,
              retailPercentage: 100,
              professionalPercentage: 0,
            },
          ],
        },
      ],
    },
    {
      beatPlanId: 8,
      beatPlanName: "Mysuru Circle",
      beatPlanBDEId: 47,
      beatPlanManagerId: 19,
      beatPlanStartPointLat: "13.32598728107961",
      beatPlanStartPointLng: "76.74123199857348",
      beatPlanEndPointLat: "18.28163113643164",
      beatPlanEndPointLng: "77.84680059440716",
      beatPlanTotalDistance: 254.0,
      beatPlanIsActive: true,
      bdeName: "Uthra BDE",
      cusDetails: [],
    },
    {
      beatPlanId: 7,
      beatPlanName: "BNGR Circle 4",
      beatPlanBDEId: 47,
      beatPlanManagerId: 19,
      beatPlanStartPointLat: "25.85921887085772",
      beatPlanStartPointLng: "77.36606140326361",
      beatPlanEndPointLat: "17.85001201198735",
      beatPlanEndPointLng: "76.37569087609442",
      beatPlanTotalDistance: null,
      beatPlanIsActive: true,
      bdeName: "Uthra BDE",
      cusDetails: [],
    },
    {
      beatPlanId: 6,
      beatPlanName: "BNGR Circle 3",
      beatPlanBDEId: 0,
      beatPlanManagerId: 0,
      beatPlanStartPointLat: "23.24483765646537",
      beatPlanStartPointLng: "76.28956116196645",
      beatPlanEndPointLat: "25.98644380104752",
      beatPlanEndPointLng: "76.88183920195516",
      beatPlanTotalDistance: 419.0,
      beatPlanIsActive: true,
      bdeName: null,
      cusDetails: [],
    },
    {
      beatPlanId: 5,
      beatPlanName: "BNGR Circle 2",
      beatPlanBDEId: 0,
      beatPlanManagerId: 0,
      beatPlanStartPointLat: "12.41091863276383",
      beatPlanStartPointLng: "77.76290309608214",
      beatPlanEndPointLat: "26.26985350185373",
      beatPlanEndPointLng: "77.3115997907683",
      beatPlanTotalDistance: null,
      beatPlanIsActive: true,
      bdeName: null,
      cusDetails: [],
    },
    {
      beatPlanId: 4,
      beatPlanName: "BNGR Circle 2 ",
      beatPlanBDEId: 0,
      beatPlanManagerId: 0,
      beatPlanStartPointLat: "17.26514457620484",
      beatPlanStartPointLng: "76.14284229007137",
      beatPlanEndPointLat: "19.44537427491214",
      beatPlanEndPointLng: "76.83665100047536",
      beatPlanTotalDistance: 371.0,
      beatPlanIsActive: true,
      bdeName: null,
      cusDetails: [],
    },
    {
      beatPlanId: 3,
      beatPlanName: "Pondichery Circle",
      beatPlanBDEId: 0,
      beatPlanManagerId: 0,
      beatPlanStartPointLat: "11.9394547",
      beatPlanStartPointLng: "79.8090608",
      beatPlanEndPointLat: "12.167165910181208",
      beatPlanEndPointLng: "78.86689354757854",
      beatPlanTotalDistance: 139.0,
      beatPlanIsActive: true,
      bdeName: null,
      cusDetails: [],
    },
    {
      beatPlanId: 2,
      beatPlanName: "BSLR Circle 1",
      beatPlanBDEId: 0,
      beatPlanManagerId: 0,
      beatPlanStartPointLat: "20.94659861628678",
      beatPlanStartPointLng: "77.00004776405153",
      beatPlanEndPointLat: "19.44537427491214",
      beatPlanEndPointLng: "76.83665100047536",
      beatPlanTotalDistance: 215.0,
      beatPlanIsActive: true,
      bdeName: null,
      cusDetails: [],
    },
    {
      beatPlanId: 1,
      beatPlanName: "Trichy Cantonment Circle",
      beatPlanBDEId: 0,
      beatPlanManagerId: 0,
      beatPlanStartPointLat: "10.8060289",
      beatPlanStartPointLng: "78.684076",
      beatPlanEndPointLat: "10.8060223",
      beatPlanEndPointLng: "78.6840881",
      beatPlanTotalDistance: 1.0,
      beatPlanIsActive: true,
      bdeName: null,
      cusDetails: [],
    },
  ],
};

export function add530Minutes(dateString) {
  // Create a Date object from the date string
  const dateObj = new Date(dateString);

  // Calculate the milliseconds for 5 hours and 30 minutes
  const millisecondsToAdd = 5 * 60 * 60 * 1000 + 30 * 60 * 1000;

  // Add the milliseconds to the date object
  dateObj.setTime(dateObj.getTime() + millisecondsToAdd);

  // Return the modified date as a string (you can format it as needed)
  return dateObj;
}
