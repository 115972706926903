export const getBaseUrl = () => {
  let url = process.env.REACT_APP_API_URL;
  return url;
};

export const getStorageBaseUrl = () => {
  var url = process.env.REACT_APP_STORAGE_API_URL;
  return url;
};

export const config = {
  baseURL: getBaseUrl(),
  version: "1.0.0",
  customerID: 1,
};

export const getCustomerListById = {
  1: {
    name: "Renaura Wellness Private Limited",
    address1: "555, AECS Layout - C Block, AECS Layout",
    address2: "Brookefield,Bengaluru, Karnataka 560037",
  },
  2: {
    name: "Renaura Wellness Private Limited",
    address1: "555, AECS Layout - C Block, AECS Layout",
    address2: "Brookefield,Bengaluru, Karnataka 560037",
  },
};

export const getCustomerInfo = getCustomerListById[config.customerID];

export const storageBaseURL = getStorageBaseUrl();

export default config;
