import React, { Component, Suspense } from "react";
import {
  Route,
  BrowserRouter as Router,
  HashRouter,
  Routes,
} from "react-router-dom";
import "./css/Global.css";
import "./css/Custom.css";
import "./assets/scss/hope-ui.scss";
import "./assets/scss/visit_today.scss";
import { getPageRights } from "./services/CommonUtil";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

/*
import About from "./component/About/About";
import Address from "./component/Address/Address";
import Tricycle from "./component/Tricycle/Tricycle";
import Truck from "./component/Truck/Truck";
import Login from "./component/Login/Login";
import ForgetPassword from "./component/Login/ForgetPassword";
import UserManagement from "./component/Admin/UserManagement";
function App() {
  return (
    <div className="App">
      <Router>
        <Route exact path="/" component={Login} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/forgetpassword" component={ForgetPassword} />
        <Route exact path="/about" component={About} />
        <Route exact path="/address" component={Address} />
        <Route exact path="/tricycle" component={Tricycle} />
        <Route exact path="/truck" component={Truck} />
        <Route exact path="/usermanagement" component={UserManagement} />
      </Router>
    </div>
  );
}
*/

const Home = React.lazy(() => import("./views/Home/Home"));
// const Home1 = React.lazy(() => import("./views/Home/Home1"));
const MyVisit = React.lazy(() => import("./views/MyVisit/MyVisit"));
const MyBilling = React.lazy(() => import("./views/MyBilling/MyBilling"));
const Login = React.lazy(() => import("./views/Login/Login"));
const VisitToday = React.lazy(() => import("./views/MyVisit/VisitToday"));
// const VisitToday = React.lazy(() => import("./views/MyVisit/MyVisitsNew"));
const ProductList = React.lazy(() => import("./views/Product/ProductList"));
const TypeOfServicesList = React.lazy(() => import("./views/TypeOfServices/TypeOfServicesList"));
const FranchiseDirectoryList = React.lazy(() => import("./views/FranchiseDirectory/FranchiseDirectoryList"));
const BrandMasterList = React.lazy(() => import("./views/BrandMaster/BrandMasterList"));
const BrandMaster = React.lazy(() => import("./views/BrandMaster/BrandMaster"));
const FeedBackForm = React.lazy(() => import("./views/FeedBackForm/FeedBackForm"));
const FeedBackFormList = React.lazy(() => import("./views/FeedBackForm/FeedBackFormList"));
const GenericList = React.lazy(() => import("./views/Generic/GenericList"));
const Generic = React.lazy(() => import("./views/Generic/Generic"));

// const NewBatch = React.lazy(() => import("./views/Master/NewBatch"));
// const BatchMaster = React.lazy(() => import("./views/Master/BatchMaster"));

const Product = React.lazy(() => import("./views/Product/Product"));
const TypeOfServices = React.lazy(() => import("./views/TypeOfServices/TypeOfServices"));
const FranchiseDirectory = React.lazy(() => import("./views/FranchiseDirectory/FranchiseDirectory"));
const UserList = React.lazy(() => import("./views/UserManagement/UserList"));
const User = React.lazy(() => import("./views/UserManagement/User"));
const Landing = React.lazy(() => import("./views/Login/Landing"));
const OrderByStatus = React.lazy(() => import("./views/MyVisit/OrderByStatus"));
const WorkingInProgress = React.lazy(() =>
  import("./views/Common/WorkingInProgress")
);

const Calendar = React.lazy(() => import("./views/Calendar/MyCalendar"));
const ForgotPassword = React.lazy(() => import("./views/Login/ForgotPassword"));

const Page401 = React.lazy(() => import("./views/Pages/page401/Page401"));
const Page404 = React.lazy(() => import("./views/Pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/Pages/page500/Page500"));
const SessionInfo = React.lazy(() =>
  import("./views/Pages/session/SessionInfo")
);
const OrderDetail = React.lazy(() =>
  import("./views/OrderDetails/OrderDetail")
);
const OrderDetailDist = React.lazy(() =>
  import("./views/OrderDetails/OrderDetailDist")
);
const BatchList = React.lazy(() =>
  import("./views/Master/BatchMaster")
);
const Batch = React.lazy(() =>
  import("./views/Master/NewBatch")
);

const OrderDetailGeneric = React.lazy(() =>
  import("./views/OrderDetails/OrderDetailGeneric")
);
const Inventory = React.lazy(() => import("./views/Inventory/Inventory"));
const InventoryList = React.lazy(() =>
  import("./views/Inventory/InventoryList")
);

const CustomerDetail = React.lazy(() =>
  import("./views/CustomerDetail/CustomerDetail")
);
const CustomerDetailNew = React.lazy(() =>
  import("./views/CustomerDetail/CustomerDetailNew")
);
const SearchMaster = React.lazy(() =>
  import("./views/CustomerDetail/SearchMaster")
);
const CustomerDetailList = React.lazy(() =>
  import("./views/CustomerDetail/CustomerDetailList")
);
const AddDistributor = React.lazy(() =>
  import("./views/DistributorDetail/AddDistributor")
);
const DistributorList = React.lazy(() =>
  import("./views/DistributorDetail/DistributorList")
);

const OrgChart = React.lazy(() =>
  import("./views/Dashboard/OrgChart")
);

const ActivityLogs = React.lazy(() =>
  import("./views/Dashboard/ActivityLogs")
);

/** Beat Plan module components */
// const BeatPlanList = React.lazy(() => 
//   import("./views/BeatPlan/BeatPlanList")
// );
const BeatPlanList = React.lazy(() =>
  import("./views/BeatPlan_New/NewBeatPlanList")
);
const BeatPlanGenerateView = React.lazy(() =>
  import("./views/BeatPlan/BeatPlanGenerateView")
);
const NewBeatPlanGenerate = React.lazy(() =>
  import("./views/BeatPlan_New/NewBeatPlanGenerate")
);
const PlannedBeats = React.lazy(() =>
  import("./views/BeatPlan_New/PlannedBeats")
);

const AssignBeatPlanView = React.lazy(() =>
  import("./views/BeatPlan_New/AssignBeatPlanView")
);

const CreateBeatPlanView = React.lazy(() =>
  import("./views/BeatPlan_New/CreateBeatPlanView")
);
const BeatPlanSelected = React.lazy(() =>
  import("./views/BeatPlan/BeatPlanSelected")
);

/** Generate QR code module components */
const BatchMasterTab = React.lazy(() =>
  import("./views/Master/BatchMaster/BatchMasterTab")
);

const SingleQRProductView = React.lazy(() =>
  import("./views/Master/SingleQRProductView")
);
const MultipleQRProductView = React.lazy(() =>
  import("./views/Master/MultipleQRProductView")
);

const ScannedQRView = React.lazy(() =>
  import("./views/ScannedQRView/ScannedQRView")
);

class App extends Component {
  render() {
    return (
      <div className="App">
        <HashRouter>
          <Suspense fallback={loading}>
            <Routes>
              <Route
                exact
                path="/login"
                name="Login Page"
                element={<Login />}
              />
              <Route
                exact
                path="/ForgotPassword"
                name="ForgotPassword Page"
                element={<ForgotPassword />}
              />
              <Route exact path="/home" name="Home Page" element={<Home />} />
              {/* <Route exact path="/home1" name="Home Page" element={<Home1 />} /> */}
              <Route
                exact
                path="/MyVisits"
                name="My Visits"
                element={<VisitToday />}
              />
              {/* <Route
                exact
                path="/MyVisitsNew"
                name="My Visits"
                element={<MyVisitsNew />}
              /> */}

              <Route
                exact
                path="/MyBilling"
                name="MyBilling"
                element={<WorkingInProgress />}
              />
              <Route
                exact
                path="/MyTasks"
                name="MyTasks"
                element={<WorkingInProgress />}
              />

              <Route
                exact
                path="/Marketing"
                name="Marketing"
                element={<WorkingInProgress />}
              />
              <Route
                exact
                path="/SalonMap"
                name="SalonMap"
                element={<WorkingInProgress />}
              />
              <Route
                exact
                path="/Calendar"
                name="Calendar"
                element={<Calendar />}
              />
              <Route
                exact
                path="/MyTravel"
                name="MyTravel"
                element={<WorkingInProgress />}
              />
              <Route
                exact
                path="/Distributor"
                name="Distributor"
                element={<WorkingInProgress />}
              />
              <Route
                exact
                path="/OrderByStatus"
                name="OrderByStatus"
                element={<OrderByStatus />}
              />

              <Route
                exact
                path="/MyBilling"
                name="Home Page"
                element={<WorkingInProgress />}
              />

              <Route
                exact
                path="/calendar"
                name="Calendar"
                element={<Calendar />}
              />
              <Route
                exact
                path="/TypeOfServicesList"
                name="TypeOfServicesList"
                element={<TypeOfServicesList />}
              />
              <Route
                exact
                path="/FranchiseDirectoryList"
                name="FranchiseDirectoryList"
                element={<FranchiseDirectoryList />}
              />
              <Route
                exact
                path="/BrandMasterList"
                name="BrandMasterList"
                element={<BrandMasterList />}
              />
              <Route
                exact
                path="/BrandMaster"
                name="BrandMaster"
                element={<BrandMaster />}
              />
              <Route
                exact
                path="/FeedBackFormList"
                name="FeedBackFormList"
                element={<FeedBackFormList />}
              />
              <Route
                exact
                path="/GenericList"
                name="GenericList"
                element={<GenericList />}
              />
              <Route
                exact
                path="/Generic"
                name="Generic"
                element={<Generic />}
              />
              <Route
                exact
                path="/TypeOfServices"
                name="TypeOfServices"
                element={<TypeOfServices />}
              />
              <Route
                exact
                path="/FranchiseDirectory"
                name="FranchiseDirectory"
                element={<FranchiseDirectory />}
              />
              <Route
                exact
                path="/FeedBackForm"
                name="FeedBackForm"
                element={<FeedBackForm />}
              />

              <Route
                exact
                path="/ProductList"
                name="ProductList"
                element={<ProductList />}
              />
              <Route
                exact
                path="/BatchList"
                name="BatchList"
                element={<BatchList />}
              />
              <Route
                exact
                path="/Batch"
                name="Batch"
                element={<BatchMasterTab />}
              // element={<Batch />}
              />

              <Route
                exact
                path="/Product"
                name="Add/ Edit Product"
                element={<Product />}
              />
              <Route
                exact
                path="/UserList"
                name="User Master"
                element={<UserList />}
              />
              <Route
                exact
                path="/User"
                name="Add/ Edit User"
                element={<User />}
              />
              <Route
                exact
                path="/Page401"
                name="Page401"
                element={<Page401 />}
              />
              <Route
                exact
                path="/Page404"
                name="Page404"
                element={<Page404 />}
              />
              <Route
                exact
                path="/Page500"
                name="Page500"
                element={<Page500 />}
              />
              <Route
                exact
                path="/SessionInfo"
                name="SessionInfo"
                element={<SessionInfo />}
              />
              <Route
                exact
                path="/OrderDetail"
                name="OrderDetail"
                element={<OrderDetail />}
              />
              <Route
                exact
                path="/OrderDetailDist"
                name="OrderDetailDist"
                element={<OrderDetailDist />}
              />
              <Route
                exact
                path="/OrderDetailGeneric"
                name="OrderDetailGeneric"
                element={<OrderDetailGeneric />}
              />
              <Route
                exact
                path="/OrgChart"
                name="OrgChart"
                element={<OrgChart />}
              />
              <Route
                exact
                path="/ActivityLogs"
                name="ActivityLogs"
                element={<ActivityLogs />}
              />

              <Route
                exact
                path="/Inventory"
                name="Inventory"
                element={<Inventory />}
              />
              <Route
                exact
                path="/InventoryList"
                name="InventoryList"
                element={<InventoryList />}
              />
              <Route
                exact
                path="/CustomerDetail"
                name="CustomerDetail"
                element={<CustomerDetail />}
              />
              <Route
                exact
                path="/CustomerDetailNew"
                name="CustomerDetailNew"
                element={<CustomerDetailNew />}
              />
              <Route
                exact
                path="/SearchMaster"
                name="SearchMaster"
                element={<SearchMaster />}
              />

              <Route
                exact
                path="/CustomerDetailList"
                name="CustomerDetailList"
                element={<CustomerDetailList />}
              />
              <Route
                exact
                path="/AddDistributor"
                name="AddDistributor"
                element={<AddDistributor />}
              />
              <Route
                exact
                path="/DistributorList"
                name="DistributorList"
                element={<DistributorList />}
              />
              <Route
                exact
                path="/BeatPlanList"
                name="BeatPlanList"
                element={<BeatPlanList />}
              />
              <Route
                exact
                path="/BeatPlanGenerateView"
                name="BeatPlanGenerateView"
                element={<BeatPlanGenerateView />}
              />
              <Route
                exact
                path="/NewBeatPlanGenerate"
                name="NewBeatPlanGenerate"
                element={<NewBeatPlanGenerate />}
              />
              <Route
                exact
                path="/PlannedBeats"
                name="PlannedBeats"
                element={<PlannedBeats />}
              />
              
              <Route
                exact
                path="/AssignBeatPlanView"
                name="AssignBeatPlanView"
                element={<AssignBeatPlanView />}
              />
              <Route
                exact
                path="/CreateBeatPlanView"
                name="CreateBeatPlanView"
                element={<CreateBeatPlanView />}
              />

              <Route
                exact
                path="/BeatPlanSelected"
                name="BeatPlanSelected"
                element={<BeatPlanSelected />}
              />
              <Route
                exact
                path="/SingleQRProductView"
                name="SingleQRProductView"
                element={<SingleQRProductView />}
              />
              <Route
                exact
                path="/MultipleQRProductView"
                name="MultipleQRProductView"
                element={<MultipleQRProductView />}
              />
              <Route
                exact
                path="/ScannedQRView"
                name="ScannedQRView"
                element={<ScannedQRView />}
              />

              <Route exact path="*" name="Landing Page" element={<Landing />} />
            </Routes>
          </Suspense>
        </HashRouter>
      </div>
    );
  }
}

export default App;
